import React from 'react';
import DeliveringMore from '../../components/deliverMore/deliveringMore';
import ControlledCarousel from '../../common/carouselTemplate/carouselTemplate';
import HomeBannerList from '../../components/homeBannerList/homeBannerList';
import HomePromosList from '../../components/homePromosList/homePromosList';
import { graphql, useStaticQuery } from 'gatsby';
import { SECTIONS } from '../../constants';
import CustomerCare from '../../components/dedicatedCustomerCare/dedicatedCustomerCare';
import DedicatedHSE from '../../components/dedicatedHSE/dedicatedHSE';
import { Helmet } from 'react-helmet';
import ogLogo from '../../images/og-logo.jpg';

const HomePageTemplate = ({ selectedLanguage, selectedRegion }) => {
  const homePageSectionsData = useStaticQuery(graphql`
    query HomePageQuery {
      allContentfulUltramarCommercial(
        filter: { identifier: { type: { eq: "homePage" } } }
      ) {
        edges {
          node {
            node_locale
            title
            slug
            metadata
            identifier {
              type
            }
            sections {
              ... on ContentfulRegions {
                id
                title
                abbreviation
                sections {
                  ... on ContentfulContentList {
                    id
                    title
                    type
                    list {
                      contentDetails {
                        raw
                      }
                      title
                      type
                      header
                    }
                  }
                  ... on ContentfulGenericSection {
                    id
                    type
                    title
                    listSection {
                      ... on ContentfulImageWithPath {
                        id
                        title
                        type
                        path
                        image {
                          file {
                            url
                            fileName
                          }
                          title
                        }
                        richText {
                          raw
                        }
                      }
                      ... on ContentfulContent {
                        id
                        type
                        title
                        contentDetails {
                          raw
                        }
                        subText
                        header
                      }
                      ... on ContentfulListOfImageWithPath {
                        id
                        listOfImagesWithPath {
                          title
                          type
                          path
                          image {
                            file {
                              url
                            }
                            title
                          }
                          richText {
                            raw
                          }
                        }
                        type
                      }
                    }
                    list_of_section {
                      type
                      title
                      listSection {
                        id
                      }
                    }
                  }
                  ... on ContentfulListOfImageWithPath {
                    id
                    type
                    title
                    listOfImagesWithPath {
                      type
                      title
                      path
                      image {
                        title
                        file {
                          url
                          fileName
                        }
                      }
                      richText {
                        raw
                      }
                    }
                    home_container {
                      type
                      title
                      textWithLink1 {
                        ... on ContentfulImageWithPath {
                          id
                          title
                          type
                          image {
                            file {
                              url
                            }
                            title
                          }
                          richText {
                            raw
                          }
                          path
                        }
                      }
                      textWithLink2 {
                        ... on ContentfulContent {
                          id
                          title
                          type
                          contentDetails {
                            raw
                          }
                          header
                          subText
                        }
                      }
                      textWithLink3 {
                        ... on ContentfulContent {
                          id
                          title
                          type
                          contentDetails {
                            raw
                          }
                          subText
                          header
                        }
                      }
                      backgroundImages {
                        file {
                          url
                        }
                        title
                      }
                    }
                  }
                  ... on ContentfulHomeContainers {
                    id
                    title
                    type
                    homecontainers {
                      type
                      title
                      textWithLink1 {
                        ... on ContentfulImageWithPath {
                          id
                          type
                          title
                          image {
                            file {
                              url
                            }
                            title
                          }
                        }
                        ... on ContentfulListOfImageWithPath {
                          id
                          type
                          title
                          listOfImagesWithPath {
                            image {
                              file {
                                url
                              }
                              title
                            }
                            type
                            title
                          }
                        }
                      }
                      textWithLink2 {
                        ... on ContentfulContent {
                          id
                          contentDetails {
                            raw
                          }
                          subText
                          title
                          type
                          header
                        }
                      }
                      textWithLink3 {
                        ... on ContentfulContent {
                          id
                          type
                          title
                          subText
                          header
                          contentDetails {
                            raw
                          }
                        }
                      }
                      backgroundImages {
                        title
                        file {
                          url
                        }
                      }
                    }
                  }
                  ... on ContentfulListOfSection {
                    id
                    title
                    type
                    listSection {
                      listSection {
                        ... on ContentfulImageWithPath {
                          id
                          type
                          title
                          path
                          image {
                            file {
                              url
                            }
                            title
                          }
                          richText {
                            raw
                          }
                        }
                        ... on ContentfulTextWithPath {
                          id
                          text
                          path
                          type
                        }
                        ... on ContentfulContent {
                          id
                          title
                          type
                          header
                          contentDetails {
                            raw
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const regionLanguageFilteredData =
    homePageSectionsData?.allContentfulUltramarCommercial?.edges?.find(
      (edge) => edge?.node?.node_locale === selectedLanguage
    );

  const homePageSections = regionLanguageFilteredData?.node?.sections?.filter(
    (region) => region?.abbreviation === selectedRegion
  )[0]?.sections;
  const homeImagesWithPath = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.IMAGES_WITH_LINK_SECTION;
  })[0]?.listSection;

  const promosDataList = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.PROMO_CONTENTLIST_ITEMS;
  })[0]?.list;

  const customerCareList = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.CUSTOMER_CARE;
  })[0]?.listSection;

  const carouselImageData = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.IMAGES_CAROUSEL;
  })[0]?.listOfImagesWithPath;

  const deliveringMoreData = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.DELIVERING_MORE_LIST.DEFAULT;
  })[0]?.listSection;

  const dedicatedHSE = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.FOUNDATION;
  })[0]?.homecontainers;

  return (
    <>
      <Helmet>
        <title>
          {regionLanguageFilteredData?.node?.title
            ? regionLanguageFilteredData?.node?.title + ' | Ultramar'
            : 'Energy Solutions for Residential and Businesses | Ultramar'}
        </title>
        {regionLanguageFilteredData?.node?.metadata && (
          <meta
            name="og:description"
            content={regionLanguageFilteredData?.node?.metadata}
          ></meta>
        )}
        <meta property="og:type" content="website"></meta>

        {regionLanguageFilteredData?.node?.title && (
          <meta
            property="og:title"
            content={regionLanguageFilteredData?.node?.title}
          ></meta>
        )}
        <meta property="og:url" content={process.env.GATSBY_DOMAIN_URL}></meta>
        <meta property="og:image" content={ogLogo}></meta>

        {regionLanguageFilteredData?.node?.metadata && (
          <meta
            name="twitter:description"
            content={regionLanguageFilteredData?.node?.metadata}
          ></meta>
        )}
        <meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>

        {regionLanguageFilteredData?.node?.title && (
          <meta
            property="twitter:title"
            content={regionLanguageFilteredData?.node?.title}
          ></meta>
        )}
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta property="twitter:image" content={ogLogo}></meta>
      </Helmet>

      <HomeBannerList homeBannerList={homeImagesWithPath} />
      <HomePromosList homePromosList={promosDataList} />
      <ControlledCarousel carouselData={carouselImageData} />
      <DeliveringMore deliveringMoreData={deliveringMoreData} />
      <CustomerCare customerCareData={customerCareList} />
      <DedicatedHSE dedicatedHSEData={dedicatedHSE} />
    </>
  );
};

export default HomePageTemplate;
