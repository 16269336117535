import * as React from 'react';
import { useState } from 'react';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../utils';
import * as Styled from './carouselTemplateStyle';

const ControlledCarousel = ({ carouselData = [] }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <Styled.CarouselTemplate activeIndex={index} onSelect={handleSelect}>
      {carouselData?.length &&
        carouselData?.map((carousel, idx) => {
          return (
            <Styled.CarouselTemplate.Item key={idx} interval={3000}>
              <a
                href={
                  isExternalUrlHref(carousel?.path)
                    ? carousel?.path
                    : addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL + carousel?.path
                      )
                }
                target={isExternalUrl(carousel?.path) ? '_blank' : '_self'}
                rel="noreferrer"
              >
                <img
                  className="d-block w-100"
                  src={carousel?.image?.file?.url}
                  alt={carousel?.title}
                />
              </a>
            </Styled.CarouselTemplate.Item>
          );
        })}
    </Styled.CarouselTemplate>
  );
};

export default ControlledCarousel;
