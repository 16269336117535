import styled from 'styled-components';
import { Row, Container, Col, Carousel } from 'react-bootstrap';
import { Link } from 'gatsby';

export const CarouselTemplate = styled(Carousel)`
  @media (max-width: 767px) {
    a {
      height: 200px;
      display: block;
      img {
        width: 303px;
        height: 200px;
        object-fit: cover;
      }
    }
  }
  .carousel-indicators [data-bs-target] {
    width: 17px !important;
    height: 17px !important;
    border: 1px solid transparent !important;
    border-radius: 17px;
    background-color: #ffffff;
    opacity: 1;
  }
  .carousel-indicators button:not(:first-child) {
    margin-left: 0.5rem;
  }

  .carousel-indicators .active {
    background-color: #00539d;
  }

  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }
`;
